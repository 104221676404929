import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { OnSelfCareAndWhyWePostponedNocturnalUnrestContent } from "../../content/blog/on-self-care-and-why-we-postponed-nocturnal-unrest"
import { SettingsContext } from "../../contexts/settings"

const OnSelfCareAndWhyWePostponedNocturnalUnrestPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = OnSelfCareAndWhyWePostponedNocturnalUnrestContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <div>
                <h1>
                    <Link to="/blog#on-self-care-and-why-we-postponed-nocturnal-unrest" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                <p>
                    {content.first}
                </p>
                <p>
                    {content.second}
                </p>
                <p>
                    {content.thirdFirst}
                </p>
                <p className="end-of-site">
                    {content.fourth}
                </p>
            </div>    
            </section>
        </Template >
    )
}

export default OnSelfCareAndWhyWePostponedNocturnalUnrestPage