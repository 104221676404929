const OnSelfCareAndWhyWePostponedNocturnalUnrestContent = {
    german: {
        date: "15. Juni 2020",
        arrow: "BLOG << ",
        headline: "On (Self-)Care and why we postponed Nocturnal Unrest to early Summer 2021",
        first: "we have given a lot of thought to what the current global pandemic means for Nocturnal Unrest. After much deliberation, discussion and reasoning, we have come to the conclusion that we will postpone the festival until (early) summer 2021. An exact date should be fixed in late autumn. ",
        second: "In order to prevent Nocturnal Unrest from disappearing until next year, we are also working on a teaser programme that will be realised in digital and analogue form. We warmly invite you to join the live stream panels, the walks and screenings. There will be updates on our homepage and by email in time!",
        thirdFirst: "Postponing Nocturnal Unrest was a difficult decision, because at the moment the issues the festival is dealing with seem more relevant than ever. Digital experiments would have certainly created new possibilities, tearing down some barriers while erecting others. However, the festival concept and many of your contributions are based on personal encounters, touch, physicality and locality. Therefore, digitalisation would have required an artistic, technical and financial redesign. Enough time to develop new concepts and enough time to do this together with you were and are especially important to us. Therefore, we have decided to postpone it: our team organise Nocturnal Unrest quasi voluntarily alongside university studies, wage labour etc., hence we did not see a way in which to implement a digital re-conceptualisation, which would have gone beyond mere live-streaming while being both artistically appropriate and caring regarding you and our team, by November. ",
        fourth: "Hopefully, a majority of the works invited can be shown on site by 2021 because our aim is to bring unrest not only into digital, but mainly into urban and theatre spaces. A postponement gives us enough time to work together with you and the team of Künstlerhaus Mousonturm on hybrid festival forms that are sensitive to analogue and digital accessibility.",
        
    },
    english: {
        date: "June 15, 2020",
        arrow: "BLOG << ",
        headline: "On (Self-)Care and why we postponed Nocturnal Unrest to early Summer 2021",
        first: "we have given a lot of thought to what the current global pandemic means for Nocturnal Unrest. After much deliberation, discussion and reasoning, we have come to the conclusion that we will postpone the festival until (early) summer 2021. An exact date should be fixed in late autumn. ",
        second: "In order to prevent Nocturnal Unrest from disappearing until next year, we are also working on a teaser programme that will be realised in digital and analogue form. We warmly invite you to join the live stream panels, the walks and screenings. There will be updates on our homepage and by email in time!",
        thirdFirst: "Postponing Nocturnal Unrest was a difficult decision, because at the moment the issues the festival is dealing with seem more relevant than ever. Digital experiments would have certainly created new possibilities, tearing down some barriers while erecting others. However, the festival concept and many of your contributions are based on personal encounters, touch, physicality and locality. Therefore, digitalisation would have required an artistic, technical and financial redesign. Enough time to develop new concepts and enough time to do this together with you were and are especially important to us. Therefore, we have decided to postpone it: our team organise Nocturnal Unrest quasi voluntarily alongside university studies, wage labour etc., hence we did not see a way in which to implement a digital re-conceptualisation, which would have gone beyond mere live-streaming while being both artistically appropriate and caring regarding you and our team, by November. ",
        fourth: "Hopefully, a majority of the works invited can be shown on site by 2021 because our aim is to bring unrest not only into digital, but mainly into urban and theatre spaces. A postponement gives us enough time to work together with you and the team of Künstlerhaus Mousonturm on hybrid festival forms that are sensitive to analogue and digital accessibility.",
        
    }
}
export {OnSelfCareAndWhyWePostponedNocturnalUnrestContent}